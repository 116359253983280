<template>
  <docs-layout> 
    <div class="space-y-4 pb-10 w-full text-left">
      <h3 class="text-xl font-bold">Default</h3>
      <p>Modal visibility can be control via v-model. This is the recommended way</p>
      <asom-button @click="toggleModal1(true)" text="Show Modal" />
      <asom-modal
        v-model="showModal1"
        dismissible
        title="Default Modal">
        <p class="text-sm text-gray-500">
          Hello from modal!
        </p>
      </asom-modal>
      <p>Modal has 2 slots: body (default), title (modal-title). All can be completely overwritten for customization</p>
      <asom-button @click="toggleModal2(true)" text="Show custom modal" />
      <asom-modal
        v-model="showModal2">
        <template #modal-title>
          <h1 class="text-3xl font-bold">Custom header</h1>
        </template>
        <p class="text-sm text-gray-500">
          Hello from modal!
        </p>
        <asom-input-date-time />
      </asom-modal>
    </div>
  </docs-layout>
</template>
<script>
import DocsLayout from '@/docs/DocsLayout.vue'
export default {
  components: {
    DocsLayout,
  },
  data() {
    return {
      showModal1: false,
      showModal2: false
    }
  },
  methods: {
    toggleModal1(value) {
      this.showModal1 = value
    },
    toggleModal2(value) {
      this.showModal2 = value
    },
  }
}
</script>